.edit {
}

.header {
  &__wrapper {
    display: flex;
  }

  &__titles {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    gap: 10px;

    @media (max-width: 550px) {
      flex-direction: column;
    }
  }
}

.tableMob {
  thead {
    border-bottom: 1px solid rgba(178, 178, 178, 0.066);
  }

  @media (max-width: 900px) {
    display: flex;

    thead {
      border-bottom: none;
    }

    tr {
      display: flex;
      flex-direction: column;
    }

    th {
      border-bottom: none !important;
    }
  }
}

.buttonEdit {
  background-color: transparent !important;
  border: none;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;

  &:hover {
    i {
      color: #6ea8fe;
    }
  }
}
