.table {
  background-color: var(--bg);
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 12px;

  &__container {
    border-right: 1px solid var(--borderTable);
    overflow: auto;
    position: relative;
  }

  &__head {
    position: sticky;
    top: 0;
    background-color: var(--bgRowTable);
    z-index: 10;
  }

  &__th_head:first-child {
    position: sticky;
    left: 0;
    z-index: 11;
    background-color: var(--bgRowTable);
  }

  &__name {
    border-right: 1px solid var(--borderTable);
    // border-left: 1px solid var(--borderTable);
  }

  &__tr {
    & > .table__td:first-child {
      position: sticky;
      background-color: var(--bg);
      left: 0;
      z-index: 9;
    }

    &_total {
      position: sticky;
      bottom: 0;
      left: 0;
      background-color: var(--bgRowTable);
      z-index: 12;

      td:first-child {
        background-color: var(--bgRowTable) !important;
      }
    }

    &:hover > td {
      background-color: var(--bgRowTableHover) !important;
    }

    &_active {
      background-color: var(--bgRowTableActive);

      &:hover {
        background-color: var(--bgRowTableActive);
      }
    }
  }

  &__th,
  &__td {
    width: max-content;
    padding: 10px;
    border-bottom: 1px solid var(--borderTable);
    white-space: nowrap;
  }

  &__th {
    &_head {
      border-right: 1px solid var(--borderTable);
    }
  }

  .headGroup {
    border-right: 1px solid var(--borderTable);
    border-left: 1px solid var(--borderTable);
    border-bottom: 1px solid var(--borderTable);

    &__start {
      border-left: 1px solid var(--borderTable);
    }
    &__end {
      border-right: 1px solid var(--borderTable);
    }
  }

  &__td {
    // cursor: pointer;

    &_primary {
      position: relative;
    }
  }

  .isSub {
    border: none;
    border-bottom: 1px solid var(--borderTable);
  }

  .cellEnd {
    border-right: 1px solid var(--borderTable);
  }

  .sort {
    width: 10px;
    height: 6px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA4IDYiPjxnIGlkPSJHcm91cCI+PHBhdGggaWQ9IlBvbHlnb24gOSIgZmlsbD0iI0JFQzNDOSIgZD0iTTQgNkw3LjQ2NDEgMS41SDAuNTM1ODk4TDQgNloiLz48L2c+PC9zdmc+');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &._active {
      transform: rotate(180deg);
    }
  }

  .nameContainer {
    padding: 2px 5px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center !important;
    width: 100% !important;
    height: 25px !important;
  }

  .nameForRows {
    justify-content: space-between;
  }

  .nameSortable {
    cursor: pointer;
    &:hover {
      padding: 2px 5px;
      background-color: var(--bgRowTableHover);
      border-radius: 10px;
    }
  }

  .name {
    display: grid;
    grid-template-columns: 1fr 25px;
    grid-template-rows: 25px;
    align-items: center;
    gap: 10px;
    width: fit-content;

    span {
      display: block;
    }
  }

  &__openRow {
    // position: absolute;
    // top: 25%;
    // right: 15px;
    // width: 25px;
    height: 25px;
    padding: 2px 0px;
    cursor: pointer;
  }

  .marker {
    display: inline-block;
    margin-right: 9px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  [data-title]:after {
    content: attr(data-title);
    background-color: rgba(0, 0, 0, 0.5);
    color: #cfcdcd;
    position: absolute;
    padding: 2px 5px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    border: 1px solid #cfcdcd;
    border-radius: 10px;
    z-index: 99999;
    visibility: hidden;
  }

  [data-title] {
    position: relative;
  }
}

.progressBar {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 75%,
    transparent
  );
}

.emptyData {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-6px);
}

.selectClose {
  position: relative;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  // border: 1px solid var(--closeColor);
  background-color: var(--bgColor);
  z-index: 2;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 1px;
    background-color: var(--closeColor);
    transform-origin: center;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg); /* Перша діагональна лінія */
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg); /* Друга діагональна лінія */
  }

  &:hover {
    &::before,
    &::after {
      height: 2px;
    }
  }
}
